<template>
  <v-sheet class="customer" style="height: calc(100vh - 140px)">
    <v-row>
      <!-- <pre>{{ productList[1]?.singlewarehouse }}</pre> -->
      <!-- {{ showingFrom }} -->
      <v-col md="12" class="py-0">
        <v-row>
          <!-- Drag and drop oprations -->
          <v-col md="12" class="pb-0">
            <v-row>
              <v-layout class="">
                <v-col
                  md="4"
                  class=""
                  v-for="(status, index) in status_list"
                  :key="`span-loop-${index}`"
                >
                  <v-card
                    @click="statusSelect(status.dbvalue)"
                    class="mx-auto"
                    :style="`border: 0.5px solid ${getBgColor(status.value)}`"
                  >
                    <v-card-actions>
                      <v-list-item class="grow px-0">
                        <v-list-item-content>
                          <v-list-item-title
                            class="custom-form-label me-0"
                            style="font-weight: 600; font-size: 18px"
                          >
                            <VAvatar
                              v-if="status.value == 'all'"
                              :size="55"
                              rounded
                              color="blue lighten-5"
                              variant="tonal"
                              class="me-2"
                            >
                              <!-- <v-icon
                                size="34"
                                :style="`color : ${getAvatarColor(status.value)}`"
                                dark
                                >person</v-icon
                              > -->
                              <span class="text-h4 blue--text font-weight-bold">
                                {{ total_data }}
                              </span>
                            </VAvatar>
                            <VAvatar
                              v-else-if="status.value == 'active'"
                              :size="55"
                              rounded
                              color="green lighten-5"
                              variant="tonal"
                              class="me-2"
                            >
                              <!-- <v-icon
                                size="34"
                                :style="`color : ${getAvatarColor(status.value)}`"
                                dark
                                >person</v-icon
                              > -->
                              <span class="text-h4 green--text font-weight-bold">
                                {{ status.product_status_count }}
                              </span>
                            </VAvatar>
                            <VAvatar
                              v-else
                              :size="55"
                              rounded
                              color="orange lighten-5"
                              variant="tonal"
                              class="me-2"
                            >
                              <!-- <v-icon
                                size="34"
                                :style="`color : ${getAvatarColor(status.value)}`"
                                dark
                                >person</v-icon
                              > -->
                              <span class="text-h4 orange--text font-weight-bold">
                                {{ status.product_status_count }}
                              </span>
                            </VAvatar>
                            <h3 class="text-h6 mb-0 d-inline" style="font-weight: 600">
                              {{ status.description }}
                            </h3>
                          </v-list-item-title>
                        </v-list-item-content>
                        <div align="center" justify="end">
                          <span class="subheading" v-if="status.value == 'all'">
                            <!-- <v-chip
                              color="#0D47A1"
                              style="font-size: 18px; color: white; font-weight: 600"
                              >{{ formatMoney (total_active + total_inactive) }}</v-chip
                            > -->
                            <h4 class="text-h4 pe-3 blue--text" style="font-weight: 600">
                              {{ formatMoney(total_active + total_inactive) }}
                            </h4>
                          </span>
                          <span class="subheading" v-else-if="status.value == 'active'">
                            <h4 class="text-h4 pe-3 green--text" style="font-weight: 600">
                              {{ formatMoney(total_active) }}
                            </h4>
                          </span>
                          <span class="subheading" v-else>
                            <h4 class="text-h4 pe-3 orange--text" style="font-weight: 600">
                              {{ formatMoney(total_inactive) }}
                            </h4>
                          </span>
                        </div>
                      </v-list-item>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-layout>
            </v-row>
            <!-- 2nd row Add Product button -->
            <div class="d-flex flex-row align-center justify-content-between py-2">
              <!-- <h1 class="custom-header-blue-text m-0">Products</h1> -->

              <ComponentHeader
                moduleType="purchaseorder"
                :dataLoading="dataLoading"
                :stat="selectstatus"
                :status-list="status_list"
                allkey="all_purchase"
                countkey="status_count"
                v-on:saveAddress="updateAddressPerson"
              ></ComponentHeader>
              <div class="mr-3">
                <AutoCompleteInput
                  hide-details
                  :hideTopMargin="true"
                  :items="othercats"
                  id="segment"
                  item-text="name"
                  item-value="value"
                  v-model="budget_category_filter"
                  v-on:change="searchOthercategory"
                  style="width: 180px"
                  placeholder="Select Budget"
                >
                </AutoCompleteInput>
              </div>
              <div class="mr-3">
                <AutoCompleteInput
                  hide-details
                  :hideTopMargin="true"
                  :items="categorys"
                  id="segment"
                  item-text="name"
                  item-value="id"
                  v-model="category_filter"
                  v-on:change="searchcategory"
                  style="width: 180px"
                  placeholder="Department Category"
                >
                </AutoCompleteInput>
              </div>
              <div class="mr-3 d-flex">
                <!-- <v-btn
                  color="blue darken-4"
                  depressed
                  tile
                  class="mr-2 white--text"
                  @click="importExcel"
                >
                  Import Excel
                </v-btn> -->
                <v-btn
                  color="blue darken-4"
                  depressed
                  tile
                  class="mr-2 white--text"
                  :to="{ name: 'product-add', query: { t: new Date().getTime() } }"
                >
                  New Product
                </v-btn>
                <v-menu
                  offset-y
                  left
                  transition="slide-y-transition"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          color="blue darken-4"
                          depressed
                          tile
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                          class="white--text"
                        >
                          <v-icon>edit_note</v-icon>
                        </v-btn>
                      </template>
                      <span>Columns arrangement and visibility</span>
                    </v-tooltip>
                  </template>
                  <v-list nav dense>
                    <v-list-item
                      draggable
                      v-on:dragstart="dragStart(index)"
                      v-on:dragend="dragEnd"
                      v-on:dragover="dragOver(index)"
                      v-on:mousedown="mouseEvent('mousedown')"
                      v-on:mouseup="mouseEvent('mouseup')"
                      v-for="(item, index) in productTableHeading"
                      :key="item.title + '_' + index"
                      class="flex-column cursor-move"
                    >
                      <div class="d-flex flex-row align-center">
                        <v-checkbox
                          :ripple="false"
                          hide-details
                          color="#0d47a1"
                          v-model="item.status"
                          class="m-0"
                        ></v-checkbox>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                        <v-icon>drag_indicator</v-icon>
                      </div>
                      <v-divider
                        v-if="index < productTableHeading.length - 1"
                        class="mt-2 mb-0"
                        style="width: 100%"
                      ></v-divider>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="12" class="pb-0 pt-1">
        <v-simple-table fixed-header class="bt-table product-simple-table">
          <template v-slot:default>
            <!-- table titles -->

            <thead style="border: 2px solid #f4f4f4; position: relative; top: 0">
              <tr>
                <th
                  v-for="(product_h, index) in modifiedTableHeading"
                  :key="'$_' + product_h.title + '_' + index + 1"
                >
                  <span v-if="product_h.title === '#'">
                    <v-checkbox
                      v-model="productTableSelectAll"
                      v-on:change="checkAll"
                      hide-details
                      color="#0d47a1"
                      class="ma-0 pa-0"
                    ></v-checkbox>
                  </span>
                  <span v-else>
                    {{ product_h.title }}
                  </span>
                </th>
              </tr>
            </thead>

            <!-- table body -->

            <!-- <pre>{{ modifiedTableHeading }} </pre> -->
            <tbody v-if="!pageLoading">
              <template v-if="productList?.length">
                <tr
                  v-for="(row, bkey) in productList"
                  :class="bkey % 2 === 0 && 'blue lighten-5'"
                  class="product-listing-tr"
                  :key="'k_' + bkey"
                >
                  <td
                    style="border-right: none !important"
                    link
                    v-on:click="routeToDetail(row?.id)"
                    v-for="(data, idx) in modifiedTableHeading"
                    :key="data.title + '_' + idx + '_' + idx * 5"
                    :width="
                      dataKey(data.title) == 'actions'
                        ? '60px'
                        : '' || dataKey(data.title) == '#'
                        ? '20px'
                        : ''
                    "
                  >
                    <template v-if="data.key == 'primary_sales_uom'">
                      {{ getValue(row, "singlewarehouse.primary_qty") }}
                      {{ getValue(row, "singlewarehouse.primary_uom") }}
                    </template>

                    <template v-if="data.key == 'secondary_purchase_uom'">
                      {{ row?.singlewarehouse?.secondary_qty }}
                      {{ row?.singlewarehouse?.secondary_uom }}
                    </template>

                    <template v-if="data.key == 'profile_logo'">
                      <ImageTemplate
                        :src="getValue(row, 'profile_logo.file.url')"
                        :maxWidth="50"
                        :maxHeight="50"
                        :aspectRatio="1"
                        :contain="false"
                        circle
                      />
                    </template>
                    <template v-if="data.key == 'added_at'">
                      <p class="my-auto py-2">
                        <v-chip class="py-0" small outlined color="primary" label>
                          <ValueTemplate
                            :value="row?.added_by?.display_name"
                            title="Admin"
                          ></ValueTemplate>
                          <!-- {{ row[getColValue(th.title)]?.value[0].createdBy_admin }} -->
                        </v-chip>
                      </p>
                      <p>
                        <v-chip small label style="font-weight: 600">
                          <!-- <ValueTemplate :value="row.formatDate" title="Created Date"></ValueTemplate> -->
                          {{ formatedateTimes(row.added_at) }}
                          <!-- {{ row[getColValue(th.title)]?.value[0].created_date }} -->
                        </v-chip>
                      </p>
                    </template>
                    <template v-if="data.key == 'updated_at'">
                      <template v-if="row.updated_at">
                        <!-- {{ formatedateTimes(row.updated_at) }} -->
                        <!-- <p class="my-auto py-2">
                      <v-chip class="py-0" small outlined color="primary" label>
                        <ValueTemplate :value="row[getColValue(th.title)]?.value[0].createdBy_admin"
                          title="Created by admin"></ValueTemplate>
                      </v-chip>
                    </p> -->
                        <p>
                          <v-chip small label style="font-weight: 600">
                            {{ formatedateTimes(row.updated_at) }}
                            <!-- {{ row[getColValue(th.title)]?.value[0].created_date }} -->
                          </v-chip>
                        </p>
                      </template>
                      <template v-else>
                    <p style=" font-weight: 600">Not modified</p>
                  </template>
                    </template>

                    <!-- {{ data.key }} -->

                    <template v-if="data.key == 'barcode'">{{ getValue(row, data.key) }}</template>

                    <template v-if="data.key == 'name'">
                      <span class="text-capitalize">
                        {{ row.name_en }} {{ row.name_cn }} ({{ row.unit_description_psu }}
                        {{ row.primary_sales_uom }} X {{ row.unit_value }} in 1
                        {{ row.secondary_purchase_uom }} )
                      </span>
                    </template>

                    <span v-if="data.key === 'category_name'" class="text-capitalize"
                      ><template v-if="row?.department?.name">
                        <!-- {{ getValue(row, data.key) }} -->
                        {{ row?.department?.name }}
                      </template>
                      <template v-else>No Category</template>
                    </span>
                    <span v-if="data.key === 'budget_name'" style="text-transform: uppercase"
                      ><template v-if="row?.otherCategory">
                        <!-- {{ getValue(row, data.key) }} -->
                        {{ row?.otherCategory }}
                      </template>
                      <template v-else>No Category</template>
                    </span>
                    <span v-if="data.key === 'brand_name'" class="text-capitalize"
                      >{{ row.brand }}
                    </span>

                    <!-- <ValueTemplate
                    v-if="data.key != 'actions' &&
                    data.key != 'activated' &&
                    data.key != '#' &&
                    typeof dataKey(data.title) == 'string'" 
                      :image="data.key === 'profile_logo'" :title="data.title" :decoration="data.decoration"
                      :value="getRowValue(row, data)"
                    > -->
                    <!-- <template #value>
                        <div
                          v-if="
                            data.key === 'uom_primary' ||
                            data.key === 'uom_secondary' ||
                            data.key === 'reorder'
                          "
                        >
                          <v-chip
                            :class="'mr-0 pa-0 px-2' + ' ' + data.decoration.customClass"
                            :outlined="data.decoration.outlined"
                            :color="data.decoration.bgColor"
                            :text-color="data.decoration.textColor"
                            small
                          >
                            <span v-if="data.key === 'reorder'">{{ row["reorder"] }}</span>
                            <span v-else>{{ row["unit_secondary"] }}</span>
                          </v-chip>
                          {{ data.key }}
                          <span v-if="data.key === 'uom_secondary'">
                          
                            {{ row.primary_sales_uom }} </span
                          >&nbsp;
                          <span v-if="data.title === 'Reorder'">{{ row["uom_primary"] }}     </span>
                          
                          <span v-else>{{ row[data.key] }}</span>
                     
                        </div>
                      </template> -->
                    <!-- </ValueTemplate> -->

                    <div v-if="data.key === '#'" @click.stop class="d-flex align-center">
                      <v-checkbox
                        v-model="row['status']"
                        hide-details
                        color="#0d47a1"
                        class="ma-0 pa-0"
                      ></v-checkbox>
                    </div>

                    <!-- Action buttons -->
                    <div
                      v-if="data.key === 'actions'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <!-- order icon -->
                      <!-- <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn depressed fab dark x-small color="blue-grey lighten-1" v-bind="attrs" v-on="on"
                            @click.stop.prevent="addOrder(row)">
                            <v-icon>mdi-cart-arrow-up</v-icon>
                          </v-btn>
                        </template>
                        <span>Order</span>
                      </v-tooltip> -->

                      <!-- edit icon -->
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            class="mx-2"
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop.prevent="routeToUpdate(row?.id)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>

                      <!-- delete key -->
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template
                          v-slot:activator="{ on, attrs }"
                          v-if="!row?.singlewarehouse?.status"
                        >
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop.prevent="deleteConfirm(row)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>

                    <div
                      v-if="data.key === 'activated'"
                      style="border-right: none !important"
                      width="100"
                    >
                      <div v-if="hasValue(row, data.key)">
                        <v-switch
                          v-model="row[data.key]"
                          class="mt-0"
                          v-on:click.prevent.stop="() => activeInactive(row[data.key], row?.id)"
                          color="green"
                        ></v-switch>
                      </div>
                    </div>

                    <!-- Name -->
                    <!-- <div v-else>
                      <div v-if="hasValue(row, data.key)"> 
                      <div v-for="(value, key, inddx) in row[data.key]" :key="'$_' + value + '_' + inddx + '_' + key">
                        <span style="font-weight: 700">{{ key.toUpperCase() }}&nbsp;</span>
                        <ValueTemplate v-model="row.name.key" :title="`Name (${data.key.toUpperCase()})`" />
                      </div>
                      </div>
                      <div class="mt-2">
                        {{ row["name_en"] }}
                        {{ row["name_cn"] }}
                        {{ row["name_my"] }}
                        ({{
                          row["unit_description_psu"] +
                          " " +
                          row["primary_sales_uom"] +
                          " " +
                          "X" +
                          " " +
                          row["unit_value"] +
                          " " +
                          "in" +
                          " " +
                          1 +
                          " " +
                          row["secondary_purchase_uom"]
                        }})
                      </div>
                    </div> -->
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="13">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no product at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>

            <tfoot v-else>
              <tr v-for="idr in 13" :key="idr">
                <td v-for="idk in 13" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>

        <v-layout v-if="productList.length" class="light-border-top product-table-bottom mt-4">
          <v-flex md6 align-self-center>
            <label class="btx-label p-4">
              Showing {{ showingFrom }} to {{ showingTo }} of {{ total }} entries</label
            >
          </v-flex>

          <v-flex md6 align-self-center>
            <v-pagination
              v-model="currentPage"
              :length="totalPages"
              :total-visible="5"
              color="blue darken-4"
            ></v-pagination>
          </v-flex>
        </v-layout>
        <DeleteTemplate
          type="products"
          :delete-text="deleteText"
          :delete-dialog="deleteDialog"
          :delete-endpoint="deleteEndpoint"
          v-on:close="deleteDialog = false"
          v-on:delete:success="getProducts()"
        >
        </DeleteTemplate>
      </v-col>
    </v-row>
    <ImportTemplate
      :dialog="importDialog"
      api-url="import-products"
      title="Import Product"
      v-on:close="importDialog = false"
    ></ImportTemplate>
  </v-sheet>
</template>

<script>
import {
  SET_SEARCH_TITLE,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { PATCH, QUERY } from "@/core/services/store/request.module";
import ComponentHeader from "@/view/components/ComponentHeader.vue";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { SearchEventBus } from "@/core/lib/search.lib";

import ValueTemplate from "@/view/components/ValueTemplate";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { All_PRPDUCTS } from "@/core/lib/product.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import { formatMoney } from "accounting-js";
import objectPath from "object-path";
import ImageTemplate from "@/view/components/Image";
import moment from "moment-timezone";
import ImportTemplate from "@/view/components/Import-Template.vue";

export default {
  name: "product-listing",
  title: "Listing Product",
  data() {
    return {
      name: null,
      productList: [],
      importDialog: false,
      budget_id: null,
      supplier_filter: [],
      total_data: 0,
      total_inactive: 0,
      total_active: 0,
      searchEnabled: false,
      searchParam: null,
      selectstatus: 1,
      supplier_filters: null,
      category_filter: null,
      total_stocks: 0,
      deactivated_quantity: 0,
      activated_quantity: 0,
      supplierLists: [],
      product_supplierss: [],
      budget_category_filter: null,
      categorys: [],
      othercats: [],
      dataLoading: false,
      total: 0,
      status_list: null,
      products: [],

      productTableHeading: [
        {
          title: "#",
          key: "#",
          status: true,
          decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        },
        {
          title: "Actions",
          key: "actions",
          status: true,
          decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        },
        {
          title: "Image",
          key: "profile_logo",
          status: true,
          decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        },
        {
          title: "status",
          key: "activated",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "",
            textColor: "blue darken-2",
            customClass: "blue darken-2",
            outlined: true,
          },
        },
        {
          title: "Code",
          key: "barcode",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "",
            textColor: "blue darken-2",
            customClass: "blue darken-2",
            outlined: true,
          },
        },
        {
          title: "Name (EN,CN,MY)",
          key: "name",
          status: true,
          decoration: { is: false, type: "chip", bgColor: "", textColor: "#fff", customClass: "" },
        },
        {
          title: "Department Category",
          key: "category_name",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "#666666",
            textColor: "#000",
            customClass: "",
            outlined: true,
          },
        },
        {
          title: "Budget Category",
          key: "budget_name",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "#666666",
            textColor: "#000",
            customClass: "",
            outlined: true,
          },
        },
        {
          title: "Brand",
          key: "brand_name",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "#666666",
            textColor: "#000",
            customClass: "",
            outlined: true,
          },
        },
        {
          title: "UOM (Primary)",
          key: "primary_sales_uom",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "blue",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "UOM (Secondary)",
          key: "secondary_purchase_uom",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "#e64a4a",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "Created At",
          key: "added_at",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "#e64a4a",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "LAST MODIFIED AT",
          key: "updated_at",
          status: true,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "#e64a4a",
            textColor: "#fff",
            customClass: "",
          },
        },
      ],
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      pageLimit: 10,
      showingFrom: 1,
      showingTo: 10,
      totalPages: null,
      currentPage: 1,
      dragOverIndexForProductTableColumns: null,
      dragStartIndexForProdctTableColumns: null,
      productTableSelectAll: false,
    };
  },
  watch: {
    productTableSelectAll(value) {
      this.products.map((_value) => (_value.status = value));
    },
    currentPage() {
      this.getProducts();
    },
  },
  methods: {
    importExcel() {
      this.importDialog = true;
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    getValue(object, key) {
      return objectPath.get(object, key);
    },
    hasValue(object, key) {
      return objectPath.has(object, key);
    },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    searchcategory() {
      this.category_id = this.category_filter;
      this.getProducts();
    },
    searchOthercategory() {
      this.budget_id = this.budget_category_filter;
      this.getProducts();
    },
    getSupplier() {
      this.$store
        .dispatch(QUERY, {
          url: "suppliers",
        })
        .then((data) => {
          this.product_supplierss = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    getData() {
      this.$store
        .dispatch(QUERY, {
          url: "data",
        })
        .then((data) => {
          this.categorys = data.departments;
          this.othercats = data.othercats;
          console.log(this.othercats);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    updateAddressPerson(param) {
      this.selectstatus = param;
      this.getProducts();
    },
    statusSelect(data) {
      this.selectstatus = data;
      this.getProducts();
    },
    getRowValue(row, data) {
      if (data.key === "profile_logo") {
        return this.getProfileImage(row[data.key]);
      } else {
        return row[data.key];
      }
    },
    activeInactive(data, id) {
      const _this = this;
      console.log(data);
      _this.$store
        .dispatch(PATCH, {
          url: `single-product/${data}/${id}`,
          data: {
            id: id,
            status: Number(data),
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getProducts();
        });
    },
    getBgColor(value) {
      if (value && value == "all") {
        return "#90caf9";
      } else if (value && value == "active") {
        return "#6da16e";
      } else if (value && value == "inactive") {
        return "#c78a8a";
      }
    },
    getAvatarColor(value) {
      if (value && value == "all") {
        return "#0d47a1";
      } else if (value && value == "active") {
        return "green";
      } else if (value && value == "inactive") {
        return "red";
      }
    },
    getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }
      return null;
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "product-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    async getProducts(search = null) {
      const _this = this;
      this.pageLoading = true;
      try {
        const {
          rows,
          current_page,
          showingFrom,
          showingTo,
          totalrows,
          totalPages,
          status_list,
          activated_quantity,
          deactivated_quantity,
          total_stocks,
          total_inactive,
          total_active,
          total_data,
        } = await All_PRPDUCTS(
          this.currentPage,
          this.selectstatus,
          this.budget_id,
          this.category_id,
          search
        );
        this.productList = rows;
        this.total = totalrows;

        this.status_list = status_list;
        this.totalPages = totalPages;
        this.total_data = total_data;
        this.total_active = total_active;
        this.total_inactive = total_inactive;

        this.currentPage = current_page;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.status_list = status_list;
        this.totalrows = totalrows;
        this.activated_quantity = activated_quantity;
        this.deactivated_quantity = deactivated_quantity;
        this.total_stocks = total_stocks;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.loadPagination = true;
      }
    },
    checkAll(e) {
      console.log({ e });
    },
    dragStart(dragStartIndex) {
      this.dragStartIndexForProdctTableColumns = dragStartIndex;
    },
    dragOver(dragOverIndex) {
      this.dragOverIndexForProductTableColumns = dragOverIndex;
    },
    dragEnd() {
      const itemToReplace = this.productTableHeading[this.dragStartIndexForProdctTableColumns];
      const itemToReplaceIndex = this.dragStartIndexForProdctTableColumns;
      const whereToDropIndex = this.dragOverIndexForProductTableColumns;

      this.productTableHeading.splice(itemToReplaceIndex, 1);
      this.productTableHeading.splice(whereToDropIndex, 0, itemToReplace);

      this.dragStartIndexForProdctTableColumns = null;
      this.dragOverIndexForProductTableColumns = null;
    },
    mouseEvent(eventType) {
      const listItem = document.querySelector(".column-arrangement-list-item");
      if (eventType === "mousedown") {
        console.log({ eventType, listItem });
        listItem.classList.add("cursor-grabbing");
        listItem.classList.remove("cursor-grab");
      } else {
        console.log({ eventType, listItem });

        listItem.classList.add("cursor-grab");
        listItem.classList.remove("cursor-grabbing");
      }
    },
    dataKey(key, value = null) {
      if (key.includes("(")) {
        if (key.includes(",")) {
          const _key = key.replace("(", "").replace(")", "").replace(" ", ",").toLocaleLowerCase();
          const arr = _key.split(",");
          return { main: arr[0], key: value ? Object.keys(value) : arr.slice(1) };
        } else {
          const _key = key.replace("(", "").replace(")", "").replace(" ", "_").toLocaleLowerCase();
          return _key;
        }
      } else {
        return key.toLocaleLowerCase();
      }
    },
    addOrder({ id }) {
      console.log({ id });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "product",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ id, barcode }) {
      this.deleteText = barcode;
      this.deleteEndpoint = `product-delete/${id}`;
      this.deleteDialog = true;
    },
  },
  mounted() {
    let _this = this;

    SearchEventBus.$on("start-search", (argument) => {
      _this.searchEnabled = false;
      _this.searchParam = argument;
      if (_this.searchParam) {
        _this.searchEnabled = true;
      }
      this.getProducts(argument);
    });
    SearchEventBus.$emit("search-template", true);
    this.getData();
    this.getSupplier();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Products");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    DeleteTemplate,
    ValueTemplate,
    ComponentHeader,
    AutoCompleteInput,
    ImageTemplate,
    ImportTemplate,
  },

  computed: {
    ...mapGetters(["listingSearch"]),
    modifiedTableHeading() {
      const _tableHadings = this.productTableHeading.filter((heading) => heading.status);
      return _tableHadings;
    },
    dimentions() {
      const screenWidth = screen.width;
      const screenHeight = screen.height;
      return { height: screenHeight, width: screenWidth };
    },
  },
};
</script>

<style scoped>
.product-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.product-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}

.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.product-table-bottom {
  position: relative !important;
  bottom: 0% !important;
}

::-webkit-scrollbar {
  height: 5px !important;
}
</style>
